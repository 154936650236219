
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap');

.loa-preview {
    color: $black;
    font-family: 'Open Sans', 'DM Sans', system-ui;
    font-size: 80% !important;
    // A4 size
    width: 210mm;
    height: 297mm;
    transform: scale(var(--paper-scale));
    transform-origin: left top;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid $vuetify-grey;
        border-radius: 8px;
        pointer-events: none;
    }

    #font-base {
        display: none;
    }

    .fit {
        overflow: hidden;
    }

    .rows-1 {
        max-height: 28px;

        .rows-blank {
            height: 28px;
        }
    }

    .rows-2 {
        max-height: 56px;
    }

    .signature {
        // keep in sync with v-img max-height
        height: 190px;
    }
}

.logo {
    margin-right: 9px;
}

.header,
.footer {
    background-color: $vuetify-grey;
}

.footer {
    font-size: 75%;
}

.content {
    border-left: 1px solid $vuetify-grey;
    border-right: 1px solid $vuetify-grey;
}

.bordered {
    .col {
        border: 1px solid $light-black;

        + .col {
            border-left: none;
        }
    }

    + .bordered .col {
        border-top: none;
    }

    .data {
        background-color: $white;
    }

    .inner {
        .col {
            border-top: none;
            border-left: none;

            + .col {
                border-right: none;
            }
        }

        .row:last-of-type {
            .col {
                border-bottom: none;
            }
        }
    }
}
