
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.wrapper {
    position: relative;
    user-select: none;
    width: var(--width);
    height: var(--height);

    &:after {
        content: '';
        border: 2px dashed $tertiary-color;
        border-radius: 5px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s ease-in-out;
    }

    &:hover:after {
        opacity: 0.8;
    }

    &.with-value:before {
        content: '';
        background-image: var(--value);
        background-size: contain;
        background-position: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &.with-value:after,
    &.loading:after {
        z-index: 1;
        pointer-events: all;
        background-color: $seashell-solid;
    }

    &.with-value:hover:after {
        opacity: 0.3;
    }
}

.signature-pad {
    background-color: $white;
}
