
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.error-signature::v-deep {
    .wrapper:after {
        content: '';
        border: 2px dashed $error;
        border-radius: 5px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.8;
        pointer-events: none;
        transition: opacity 0.2s ease-in-out;
    }
}
